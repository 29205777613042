<template>
  <div class="record">
    <section class="head">
      <h1>{{ resultData.aiSparringName }}</h1>
      <div class="head-info">
        <div class="img">
          <img :src="resultData.portrait" alt="portrait" />
        </div>
        <div class="user">
          <p>
            <span
              >{{ $t("Pub_Lab_UserName") }}：<OpenData
                type="userName"
                :openid="resultData.realName"
            /></span>
            <!-- 姓名： -->
            <span>{{ $t("cm_account") }}：{{ resultData.account }}</span>
            <!-- 账号： -->
          </p>
          <p class="depart" v-if="resultData.department">
            <!-- 部门： -->
            {{ $t("CM_Department") }}：<template
              v-for="(str, strIndex) in resultData.department.split('/')"
              :key="strIndex"
            >
              <OpenData type="departmentName" :openid="str" />
              <span
                v-if="strIndex + 1 < resultData.department.split('/').length"
                >/</span
              >
            </template>
          </p>
        </div>
      </div>
    </section>

    <section class="statistics">
      <div class="content">
        <a-row :gutter="16">
          <a-col :span="6">
            <p>
              {{ $t("exam.passing_score") }}：{{ resultData.passScore }}
              {{ $t("exam.score") }}
            </p>
            <!-- 及格分数： -->
            <p>
              {{ $t("PracticeDuration") }}：{{
                formatSeconds(resultData.durationSeconds)
              }}
            </p>
            <!-- 对练用时： -->
          </a-col>
          <a-col :span="6">
            <p>
              {{ $t("CM_GetScore") }}：{{ aiSparringResult.totalScore }}
              {{ $t("exam.score") }}
            </p>
            <!-- 得分： -->
            <p>
              {{ $t("exam.join_nums") }}：{{ resultData.sparringNumber
              }}{{ $t("practice.number_of_times") }}
            </p>
            <!-- 参与次数： -->
          </a-col>
          <a-col :span="6">
            <p>&nbsp;</p>
            <p>
              {{ $t("PracticeStatus") }}：{{
                resultData.isPass == 1 ? $t("Pub_Passed") : $t("CM_NoPass")
              }}
            </p>
            <!-- 对练状态： -->
          </a-col>
          <a-col :span="6"> </a-col>
        </a-row>
      </div>
    </section>

    <section class="content">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane :key="1" :tab="$t('ResultAnalysis')">
          <div class="info">
            <h3>{{ $t("AnalysisRadar") }}</h3>
            <div id="myEcharts"></div>
            <div class="eval" v-if="currentTemp">
              <!-- <h3>{{ currentTemp.name }}</h3> -->
              <div class="tab">
                <div
                  class="tab-item"
                  :class="currentIndex === i && 'active'"
                  v-for="(item, i) in aiSparringResult.quaDetails"
                  :key="i"
                  @click="handleChartLabel(i)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="level">
                <div class="level-item" v-for="(item, i) in currentTemp.subList" :key="i">
                  <div class="name">{{ item.name }}</div>
                  <a-progress
                    class="progress"
                    :percent="item.score"
                    :strokeWidth="12"
                    :format="(percent) => $t('exam.nth_score', [percent])"
                  />
                </div>
              </div>
              <!-- 评语 -->
              <h3>{{ $t('CM_LB_Reviews') }}</h3>
              <div class="desc">{{ currentTemp.eval }}</div>
            </div>
            <h3>{{ $t("dialogue_review") }}</h3>
            <div class="dialogue">
              <div
                v-for="(item, index) in aiSparringResult.messages"
                :key="index"
              >
                <div class="dialogue-item customer" v-if="item.sender == 1">
                  <div class="user">{{ $t("cm_customer") }}</div>
                  <div class="text">{{ item.content }}</div>
                </div>
                <div class="dialogue-item sales" v-if="item.sender == 2">
                  <div class="user">{{ $t("cm_sales") }}</div>
                  <div class="text">{{ item.content }}</div>
                </div>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane
          :key="2"
          :tab="$t('MonitoringDetails')"
          v-if="isOpenMonitor == 1"
        >
          <div class="monitor" id="pageTop">
            <a-spin :spinning="monitorLoading">
              <template v-if="monitorData.length">
                <div class="cards">
                  <div
                    class="monitor-item"
                    v-for="item in monitorData"
                    :key="item.imageId"
                  >
                    <div class="img">
                      <img :src="item.url" alt="头像" />
                    </div>
                    <div class="text">
                      <p>
                        {{
                          moment(item.createTime).format("YYYY-MM-DD HH:mm:ss")
                        }}
                      </p>
                      <p>
                        {{ $t("cm_face_matching") }}：{{
                          item.isFaceMatch == 1 ? $t("cm_yes") : $t("cm_no")
                        }}
                      </p>
                      <p>
                        {{ $t("cm_are_you_serious") }}：{{
                          item.isFocusMatch == 1 ? $t("cm_yes") : $t("cm_no")
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="page-Wrap">
                  <a-pagination
                    show-quick-jumper
                    hideOnSinglePage
                    :show-total="(total) => $t('XB_InTotal', [monitorTotal])"
                    :defaultPageSize="20"
                    v-model:current="currentPage"
                    :total="monitorTotal"
                    @change="pageChange"
                  >
                    <template #itemRender="{ type, originalElement }">
                      <a-button v-if="type === 'prev'">{{
                        $t("cm_pre")
                      }}</a-button>
                      <a-button v-else-if="type === 'next'">{{
                        $t("cm_next")
                      }}</a-button>
                      <renderVNode
                        v-else
                        :vnode="originalElement"
                      ></renderVNode>
                    </template>
                  </a-pagination>
                </div>
              </template>
              <a-empty v-else style="padding: 50px 0" />
            </a-spin>
          </div>
        </a-tab-pane>
      </a-tabs>
    </section>
  </div>
</template>

<script>
import { reactive, toRefs, nextTick } from "vue";
import { useRoute } from "vue-router";
import { formatSeconds, upFavicon } from "@/utils/tools";
import moment from "moment";
import ls from "@/utils/local-storage";
import { currentHost } from "@/utils/request.js";
import { platformConfig } from "@/api/other";
import { wxworkConfig, companyInfo } from "@/api/user";
import { aiSparringMemberResult, aiSparringMonitor } from "@/api/project";
import OpenData from "@/components/OpenData.vue";
import * as echarts from "echarts";
const renderVNode = (_, { attrs: { vnode } }) => vnode;

export default {
  components: {
    OpenData,
    renderVNode,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      taskId: Number(route.query.taskId || 0),
      detailId: Number(route.query.detailId || 0),
      recordId: route.query.recordId || '',
      dialogueId: route.query.dialogueId || '',
      userId: Number(route.query.userId || 0),
      resultData: {},
      aiSparringResult: {},
      isOpenMonitor: 0,
      activeKey: 1,
      monitorData: [],
      monitorTotal: 0,
      monitorLoading: true,
      currentPage: 1,
      currentIndex: -1,
      currentTemp: null,
      lastStr: ['需要继续努力。', '需要加强练习。', '需要多多练习。'],
      chart: null,
      labelName: '',
    });

    companyInfo().then((res) => {
      if (res.ret == 0) {
        if (res.data.logo2) upFavicon(res.data.logo2);
      }
    });

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    const isWxWork = /wxwork/i.test(ua);
    const isDingding = /dingtalk/i.test(ua);
    platformConfig({ site: currentHost }).then((res) => {
      if (res.data.platform == 26) {
        if (isWxWork) {
          console.log("isWxWork");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.search,
            jsTicketType: "2", // 不为空 - 获取企业标识 为空 则为应用标识
          }).then((r) => {
            let d = r.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: d.corpId, // 必填，公众号的唯一标识
              timestamp: d.timestamp, // 必填，生成签名的时间戳
              nonceStr: d.nonceStr, // 必填，生成签名的随机串
              signature: d.signature, // 必填，签名，见附录1
              jsApiList: [
                "checkJsApi",
                "startLiving",
                "replayLiving",
                "agentConfig",
                "invoke",
                "startMeeting",
              ],
              openTagList: ["wx-open-launch-app"],
            });
            wx.ready(() => {
              wxworkConfig({
                url:
                  location.protocol +
                  "//" +
                  location.hostname +
                  location.pathname +
                  location.port +
                  location.search,
              }).then((res) => {
                let d2 = res.data;
                wx.agentConfig({
                  debug: true,
                  corpid: d2.corpId,
                  agentid: d2.agentid,
                  timestamp: d2.timestamp,
                  nonceStr: d2.nonceStr,
                  signature: d2.signature,
                  jsApiList: ["selectExternalContact"],
                  success: function (res) {
                    // 回调
                    console.log("agentConfigSuccess：" + JSON.stringify(res));
                  },
                  fail: function (res) {
                    if (res.errMsg.indexOf("function not exist") > -1) {
                      alert($t("the_version_is_too_low_please_upgrade"));
                      // alert("版本过低请升级");
                    }
                  },
                });
              });
            });
            wx.error((res) => {
              console.log(res, "---");
            });
          });
        } else {
          console.log("isWxWork web");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.port +
              location.search,
          }).then((res) => {
            let d = res.data;
            wx.agentConfig({
              debug: true,
              corpid: d.corpId,
              agentid: d.agentid,
              timestamp: d.timestamp,
              nonceStr: d.nonceStr,
              signature: d.signature,
              jsApiList: ["selectExternalContact"],
              success: function (res) {
                // 回调
                console.log("agentConfigSuccess：" + JSON.stringify(res));
              },
              fail: function (res) {
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert($t("the_version_is_too_low_please_upgrade"));
                  // alert("版本过低请升级");
                }
              },
            });
          });
        }
      } else if (res.data.platform == 31 && isDingding) {
        let dingCorpid = ls.get("dingCorpid");
        let ddInit = window.DTOpenData.init(dingCorpid);
        console.log("ddInit:" + ddInit);
      }
    });

    const initChart = (data) => {
      let seriesData = data.map((item) => item.score);
      let max = Math.max.apply(null, seriesData);
      let indicatorData = data.map((item, i) => ({
        name: item.name,
        max: max * 1.2,
        // color: i === 0 ? '#1890ff' : '#bbb',
      }));
      let myEcharts = document.getElementById("myEcharts");
      state.chart = echarts.init(myEcharts);
      state.chart.setOption({
        radar: {
          indicator: indicatorData,
          triggerEvent: true,
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: seriesData,
              },
            ],
          },
        ],
      });
      // state.chart.on('click', function (params) {
      //   if (params.componentType === 'radar' && state.labelName !== params.name) {
      //     // 点击标签高亮
      //     state.labelName = params.name;
      //     indicatorData.forEach((item, index) => {
      //       if (item.name === params.name) {
      //         item.color = '#1890ff';
      //         handleChartLabel(index);
      //       } else {
      //         item.color = '#bbbbbb';
      //       }
      //     })
      //     state.chart.setOption({
      //       radar: {
      //         indicator: indicatorData,
      //         triggerEvent: true,
      //       },
      //     })
      //   }
      // });
    };

    const getMonitor = (scroll = false) => {
      aiSparringMonitor({
        taskId: state.taskId,
        detailId: state.detailId,
        recordId: state.recordId,
        page: state.currentPage,
        pageSize: 20,
      }).then((res) => {
        state.monitorLoading = false;
        if (res.ret === 0) {
          state.monitorTotal = res.data.totals;
          state.monitorData = res.data.list || [];
          if (scroll) {
            setTimeout(() => {
              document.getElementById("pageTop").scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 0);
          }
        }
      });
    };

    const pageChange = (n) => {
      state.currentPage = n;
      getMonitor(true);
    };

    aiSparringMemberResult({
      taskId: state.taskId,
      detailId: state.detailId,
      dialogueId: state.dialogueId,
      userId: state.userId,
    }).then((res) => {
      if (res.ret == 0) {
        state.resultData = res.data;
        state.aiSparringResult = res.data.aiSparringResult;
        state.isOpenMonitor = res.data.isOpenMonitor;
        if (state.isOpenMonitor == 1 && state.recordId) {
          getMonitor();
        }
        nextTick(() => {
          initChart(res.data.aiSparringResult.scoreDetails);
          handleChartLabel(0)
        });
      }
    });

    const handleChartLabel = (index) => {
      if (state.currentIndex === index) return;
      state.currentIndex = index;
      if (state.currentIndex === -1 || !state.aiSparringResult.quaDetails) return;
      const current = state.aiSparringResult.quaDetails[state.currentIndex];
      state.currentTemp = current;
      if (current.evl) return false;

      // 1.开头 生成一级标签评语
      current.eval = generateLabelEval(current.score);
      if (current.score >= 100) return;

      // 2.中间 生成三级标签评语 取分数最低三个
      current.eval += '其中';
      let levelChildren = [];
      current.subList.forEach((wrap) => {
        if (wrap.subList) {
          levelChildren.push(...wrap.subList);
        }
      })
      levelChildren = levelChildren.filter(item => item.score < 100);
      levelChildren.sort((a, b) => a.sort - b.sort);
      const threeArr = levelChildren.filter((item, i) => i < 3);
      threeArr.forEach((item, i) => {
        current.eval += item.name;
        current.eval += i === threeArr.length - 1 ? '有所欠缺，' : '、';
      })

      // 3.结尾 随机生成一个
      const i = Math.floor(Math.random() * state.lastStr.length);
      current.eval += state.lastStr[i];
    };
    const generateLabelEval = (score = 0) => {
      let str = '';
      if (score >= 100) {
        str = '该项优秀，继续保持哦！';
      } else if (score >= 80) {
        str = '该项表现较好，';
      } else if (score >= 60) {
        str = '该项表现一般，'
      } else {
        str = '该项表现较差，'
      }
      return str;
    }

    return {
      formatSeconds,
      moment,
      ...toRefs(state),
      pageChange,
      handleChartLabel,
    };
  },
};
</script>

<style lang="less" scoped>
.record {
  background-color: #eff1f4;
  padding: 24px 0;
  min-height: 100vh;
}
.head {
  .mixinWrap();
  padding: 24px;
  background-color: #fff;
  h1 {
    color: #333;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 16px;
    .mixinEllipsis(50px);
    text-align: center;
  }
  &-info {
    .mixinFlex(space-between; center);
    .img {
      .mixinImgWrap(60px; 60px);
      border-radius: 50%;
    }
    .user {
      width: calc(100% - 80px);
    }
    p {
      margin: 0;
      font-size: 16px;
      span {
        padding-right: 30px;
      }
    }
    .depart {
      span {
        padding-right: 0;
      }
    }
  }
}
.statistics {
  margin-top: 24px;
  .content {
    .mixinWrap();
    background-color: #fff;
    padding: 12px 24px;
    p {
      margin: 12px 0;
      font-size: 16px;
    }
  }
}
.content {
  .mixinWrap();
  background-color: #fff;
  padding: 8px 24px 24px;
  margin-top: 24px;
  ::v-deep(.ant-tabs-nav) {
    .ant-tabs-tab {
      font-size: 18px;
    }
  }
  .info {
    h3 {
      font-size: 18px;
    }
    #myEcharts {
      height: 320px;
    }
    .dialogue {
      border-radius: 8px;
      margin-top: 16px;
      line-height: 1.5;
      &-item {
        margin-bottom: 8px;
        &.customer {
          margin: 6px 0;
          padding: 12px;
        }
        &.sales {
          background-color: #e6eefd;
          border-radius: 8px;
          margin: 6px 0;
          padding: 12px;
        }
        .user {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 6px;
        }
        .text {
          font-size: 14px;
        }
      }
    }
  }
  .monitor {
    padding: 12px 8px 24px;
    .cards {
      .mixinFlex(flex-start);
      flex-wrap: wrap;
    }
    &-item {
      width: 208px;
      margin-bottom: 24px;
      margin-right: 24px;
      box-shadow: 0 0 10px rgba(148, 148, 148, 0.3);
      border-radius: 8px;
      .img {
        .mixinImgWrap(208px; 284px);
        border-radius: 8px 8px 0 0;
      }
      .text {
        padding: 6px 12px;
        p {
          margin: 0 4px;
          font-size: 14px;
          line-height: 1.7;
        }
      }
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  .eval {
    margin: 20px 0;
    h3 {
      margin: 12px 0;
      font-weight: bold;
    }
    .tab {
      display: flex;
      &-item {
        position: relative;
        padding-left: 30px;
        line-height: 58px;
        font-size: 18px;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 14px;
          transform: translateY(-50%);
          width: 1px;
          height: 18px;
          background-color: #f7f7f7;
        }
        &:first-child {
          padding-left: 0;
        }
        &:first-child::before {
          width: 0;
        }
      }
      .active {
        font-weight: 600;
        color: var(--theme);
      }
    }
    .level {
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .name {
          min-width: 120px;
          margin: 0 10px;
          text-align: right;
        }
        .progress {
          width: 500px;
        }
      }
    }
    .desc {
      text-indent: 2em;
    }
    ::v-deep(.ant-progress-inner) {
      border-radius: 0;
      .ant-progress-bg {
        border-radius: 0 !important;
      }
    }
  }
}
</style>
